<template>
  <div class="md-school-class-component">
    <div class="md-school-con" v-if="schoolListInfo.length">
      <img src="@/assets/img/school.png" alt="">
      <!-- <el-carousel height="160px" :interval="0">
        <el-carousel-item v-for="(item, index) in schoolListInfo" :key="index">
          <div class="img-box">
            <template v-for="(s, i) in item">
              <img class="swiper-img" :src="`/video-dev${s.cover}`" alt="" :key="i" v-if="s.cover">
            </template>
          </div>
        </el-carousel-item>
      </el-carousel> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    schools: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      schoolListInfo: []
    }
  },
  watch: {
    schools: {
      handler (val) {
        console.log(val,'val');
        for (let i = 0; i < val.length; i += 6) {
          this.schoolListInfo.push(val.slice(i, i + 6))
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    // console.log(this.schoolListInfo)
  }
}
</script>
<style lang="less" scoped>
.md-school-class-component {
  width: 100%;
  // padding-top: 50px;
  max-width: 1920px;
  margin: 0 auto;
  .md-school-con {
    width: 100%;
    margin: 0 auto;
    max-width: 1920px;
    img {
        width: 100%;
        height: 100%;
      }
    // .img-box {
    //   width: 100%;
    //   padding: 0 0;
    //   display: flex;
    //   align-items: center;
    //   box-sizing: border-box;
    //   img {
    //     margin: 0 25px;
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }
}
/deep/ .el-carousel__container .el-carousel__item .swiper-img {
  height: 130px;
  width: 140px !important;
  margin: 0 35px 0 0px;
  &:last-child {
    margin-right: 0px !important;
  }
}
/deep/ .el-carousel__item.is_animating {
  transition: 1s all;
}
</style>
